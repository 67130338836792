import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HelpersService} from '@core/utils/helpers.service';

@Component({
    selector: 'ngx-form-field-media-dialog',
    templateUrl: './upload-media-dialog.component.html',
    styleUrls: ['./upload-media-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadMediaDialogComponent implements OnInit {
    /* Enable image and/or video media */
    @Input() enableImage: boolean = true;
    @Input() enableVideo: boolean = false;
    imageFormats: string[] = ['jpeg', 'jpg', 'png'];
    videoFormats: string[] = ['mov', 'mp4'];
    fileType: string = '';

    acceptedFileTypes: string[] = []; // = [...this.imageFormats, ...this.videoFormats];

    fileUploadForm: FormGroup = this.fb.group({
        fileList: this.fb.array([], Validators.required),
    });

    constructor(
        protected ref: NbDialogRef<UploadMediaDialogComponent>,
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private helpersService: HelpersService,
    ) {}

    ngOnInit(): void {
        if (this.enableImage) {
            this.acceptedFileTypes.push(...this.imageFormats);
            if (this.enableVideo) {
                this.fileType = 'Videos/Images';
                this.acceptedFileTypes.push(...this.videoFormats);
            } else {
                this.fileType = 'Images';
            }
        }
    }
    onFileAdded(file) {
        const fr = new FileReader();
        fr.readAsDataURL(file.file);
        fr.onload = (e) => {
            file = {
                ...file,
                url: e.target.result,
            };
            this.fileList.push(this.createItem(file));
            this.changeDetectorRef.detectChanges();
        };
    }
    createItem = (file): FormGroup => {
        return this.fb.group({
            name: file.name,
            status: file.status,
            fileFormat: file.fileFormat,
            dataOperationType: 'FULL_REFRESH',
            fileLoadType: 'SNAPSHOT',
            tableArray: [],
            progress: 0,
            size: this.helpersService.formatFileSize(file.size),
            file: file.file,
            url: file.url,
        });
    };
    get fileList() {
        return this.fileUploadForm.get('fileList') as FormArray;
    }
    removeFile(indexToRemove: number) {
        this.fileList.removeAt(indexToRemove);
    }

    save() {
        this.ref.close({contents: this.fileList.value});
    }
    cancel() {
        this.ref.close();
    }
}
